export const isLocal = () => {
  const LOCAL_DOMAINS = ['localhost', '127.0.0.1']
  return LOCAL_DOMAINS.includes(window.location.hostname)
}

export const isBestGoldenOfferCom = () => {
  const BEST_GOLDEN_OFFER_COM = ['bestgoldenoffer.com']
  return BEST_GOLDEN_OFFER_COM.includes(window.location.hostname)
}

export const isStayFitUBodyPro = () => {
  const STAY_FIT_U_BODY_PRO = ['stayfitubody.pro']
  return STAY_FIT_U_BODY_PRO.includes(window.location.hostname)
}

export const isUBodyRu = () => {
  const U_BODY_RU = ['u-body.ru']
  return U_BODY_RU.includes(window.location.hostname)
}

export const isStepVibeFitCom = () => {
  const STEP_VIBE_FIT_COM = ['stepvibefit.com']
  return STEP_VIBE_FIT_COM.includes(window.location.hostname)
}

export const isHypeColorQubeCom = () => {
  const HYPE_COLOR_QUBE_COM = ['hypecolorqube.com']
  return HYPE_COLOR_QUBE_COM.includes(window.location.hostname)
}

export const isBodyMagicSnapPro = () => {
  const BODY_MAGIC_SNAP_PRO = ['body.magicsnap.pro']
  return BODY_MAGIC_SNAP_PRO.includes(window.location.hostname)
}

export const isUstretchFitCom = () => {
  const USTRETCH_FIT_COM = ['ustretchfit.com']
  return USTRETCH_FIT_COM.includes(window.location.hostname)
}

export const isUstretchSetCom = () => {
  const USTRETCH_SET_COM = ['ustretchset.com']
  return USTRETCH_SET_COM.includes(window.location.hostname)
}

export const isFroliCartFlashCom = () => {
  const FROLI_CART_FLASH_COM = ['frolicartflash.com']
  return FROLI_CART_FLASH_COM.includes(window.location.hostname)
}

export const isFileDownloadZoneCom = () => {
  const FILE_DOWNLOAD_ZONE_COM = ['filedownloadzone.com']
  return FILE_DOWNLOAD_ZONE_COM.includes(window.location.hostname)
}

export const isSmartDeepRu = () => {
  const SMART_DEEP_RU = ['smartdeep.ru']
  return SMART_DEEP_RU.includes(window.location.hostname)
}
